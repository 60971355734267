import * as React from "react";
import Layout from "../components/layout";
import aventureImg from "../images/aventure-specs.jpg";
import aventureVideo from "../images/Aventure_Video.mp4";
import "../styles/global-styles.css";

// styles
const imageStyles = {
  marginTop: "1px",
  marginBottom: 0,
  width: "100%",
};
const videoStyles = {
  marginTop: "-4px",
  marginBottom: 0,
  width: "100%",
};
const pageStyles = {
  color: "#232129",
  padding: 0,
  margin: 0,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  maxHeight: "100vh",
  overflow: "hidden"
};

// markup
const Aventure = () => {
  return (
    <main style={pageStyles}>
      <title>Aventure Specs</title>
      <img style={imageStyles} src={aventureImg} />
      <video
        style={videoStyles}
        crossOrigin="anonymous"
        controls={false}
        autoPlay
        loop
        muted
      >
        <source src={aventureVideo} type="video/mp4" />
      </video>
    </main>
  );
};

export default Aventure;
